import * as React from "react";
import {
    Button,
    Dropdown,
    Header,
    Icon,
    Loader,
    Menu,
    Message,
    Table,
} from "semantic-ui-react";
import {PaginatedTable} from "../../components/paginatedTable";
import {Sheet} from "../../components/sheet";
import {ZeroStateView} from "../../components/zeroStateView";
import {
    ApiResult,
    AuthAgency,
    PageResult,
    AgencyGroupTypeEnum,
    ClientGroup,
    FullUser,
    SortingConfig,
} from "@bryxinc/lunch/models";
import {
    ChangeManagerStatusModal,
    ChangeManagerStatusModalViewStatus,
} from "./changeManagerStatusModal";
import {
    ConfirmRemoveClientModal,
    ConfirmRemoveClientModalViewStatus,
} from "./confirmRemoveClientModal";
import {
    EditClientGroupsModal,
    EditClientGroupsModalViewStatus,
} from "./editClientGroupsModal";
import {
    EditEmailAlertsModal,
    EditEmailAlertsModalViewStatus,
} from "./editEmailAlertsModal";
import {EditGroupOverlay} from "./editGroupOverlay";
import {
    EditUserInfoModal,
    EditUserInfoModalViewStatus,
} from "./editUserInfoModal";
import {
    PasswordResetEmailModal,
    PasswordResetEmailModalViewStatus,
} from "./passwordResetEmailModal";
import {
    SetClientExternalIdModal,
    SetClientExternalIdModalViewStatus,
} from "./setClientExternalIdModal";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ViewUserSheetViewStatus =
    | { key: "hidden" }
    | { key: "shown"; userId: string };

export interface ViewUserSheetProps
    extends RouteComponentProps,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
    viewStatus: ViewUserSheetViewStatus;
    onUpdateUser: () => void;
    onDismiss: (requiresReload: boolean) => void;
}

export interface ViewUserSheetState {
    status:
        | { key: "loading" }
        | { key: "ready"; user: FullUser }
        | { key: "error"; message: string };
    overlay:
        | { key: "none" }
        | { key: "editInfo" }
        | { key: "confirmRemoveUser" }
        | { key: "setExternalId" }
        | { key: "editGroups" }
        | { key: "editGroup"; group: ClientGroup }
        | { key: "changeManagerStatus" }
        | { key: "passwordResetEmail" }
        | { key: "editEmailAlerts" };
    groupsTabType: AgencyGroupTypeEnum;
    userHasNoDispatchGroups: boolean;
}

export class ViewUserSheet extends React.Component<ViewUserSheetProps,
    ViewUserSheetState> {
    private groupsTable: PaginatedTable<ClientGroup> | null = null;

    constructor(props: ViewUserSheetProps) {
        super(props);

        this.state = ViewUserSheet.getInitialState();
    }

    private static getInitialState(): ViewUserSheetState {
        return {
            status: {key: "loading"},
            overlay: {key: "none"},
            groupsTabType: AgencyGroupTypeEnum.dispatch,
            userHasNoDispatchGroups: false,
        };
    }

    componentDidMount() {
        const viewStatus = this.props.viewStatus;
        if (viewStatus.key == "shown") {
            this.loadUser(viewStatus.userId);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: ViewUserSheetProps) {
        const {viewStatus} = this.props;
        const newViewStatus = newProps.viewStatus;

        const switchingUsers =
            viewStatus.key == "shown" &&
            newViewStatus.key == "shown" &&
            viewStatus.userId != newViewStatus.userId;
        const opening = viewStatus.key == "hidden" && newViewStatus.key == "shown";
        if (newViewStatus.key == "shown" && (switchingUsers || opening)) {
            this.setState(ViewUserSheet.getInitialState(), () => {
                this.loadUser(newViewStatus.userId);
            });
        }
    }

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortingConfig: SortingConfig<string>,
        callback: (result: ApiResult<PageResult<ClientGroup>>) => void,
    ) {
        if (this.props.viewStatus.key == "hidden") {
            return;
        }
        this.props.api.getClientGroups(
            this.props.selectedAgency.id,
            this.props.viewStatus.userId,
            this.state.groupsTabType,
            limit,
            activePage,
            searchString,
            (result: ApiResult<PageResult<ClientGroup>>) => {
                callback(result);

                if (result.success) {
                    this.setState({
                        userHasNoDispatchGroups: result.value.count == 0,
                    });
                }
            },
        );
    }

    private isSelf(): boolean {
        return (
            this.state.status.key == "ready" &&
            this.state.status.user.id == this.props.local.selfId
        );
    }

    private closeOverlay() {
        this.setState({overlay: {key: "none"}});
    }

    private loadUser(userId: string) {
        this.setState({status: {key: "loading"}});
        this.props.api.getUser(this.props.selectedAgency.id, userId, (result) => {
            if (result.success == true) {
                this.setState({
                    status: {
                        key: "ready",
                        user: result.value,
                    },
                });
            } else {
                this.props.local.logWarn(
                    `Failed to get user: ${result.debugMessage || result.message}`,
                );
                this.setState({
                    status: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
    }

    private updateGroupsTabType(newType: AgencyGroupTypeEnum) {
        this.setState(
            {groupsTabType: newType},
            () => this.groupsTable && this.groupsTable.reload(),
        );
    }

    private renderGroupsSection(): JSX.Element | null {
        if (this.state.status.key != "ready") {
            return null;
        }

        const user = this.state.status.user;
        return (
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                }}
            >
                <Menu pointing secondary style={{marginBottom: "1em"}}>
                    <Menu.Item
                        active={this.state.groupsTabType == AgencyGroupTypeEnum.dispatch}
                        onClick={() =>
                            this.updateGroupsTabType(AgencyGroupTypeEnum.dispatch)
                        }
                    >
                        {this.props.t("members.users.viewUserSheet.dispatchTab")}
                    </Menu.Item>
                    <Menu.Item
                        active={this.state.groupsTabType == AgencyGroupTypeEnum.messaging}
                        onClick={() =>
                            this.updateGroupsTabType(AgencyGroupTypeEnum.messaging)
                        }
                    >
                        {this.props.t("members.users.viewUserSheet.messagingTab")}
                    </Menu.Item>
                </Menu>
                <PaginatedTable
                    {...this.props}
                    uniqueKey="groups"
                    ref={(r) => (this.groupsTable = r)}
                    sortable={false}
                    fixedEntriesPerPage={10}
                    rightItem={
                        <Button
                            compact
                            color="green"
                            icon="pencil"
                            content={
                                this.state.groupsTabType == AgencyGroupTypeEnum.dispatch
                                    ? this.props.t("members.clients.dispatchGroups")
                                    : this.props.t("members.clients.messagingGroups")
                            }
                            onClick={() => this.setState({overlay: {key: "editGroups"}})}
                        />
                    }
                    zeroStateView={
                        this.state.groupsTabType == AgencyGroupTypeEnum.dispatch ? (
                            <div>
                                <Message
                                    icon="warning circle"
                                    warning
                                    header={this.props.t(
                                        "members.clients.noDispatchGroupsHeader",
                                    )}
                                    content={this.props.t(
                                        "members.clients.noDispatchGroupsMessage",
                                    )}
                                />
                                <Button
                                    style={{width: "100%"}}
                                    color="green"
                                    icon="pencil"
                                    content={this.props.t("members.clients.dispatchGroups")}
                                    onClick={() =>
                                        this.setState({overlay: {key: "editGroups"}})
                                    }
                                />
                            </div>
                        ) : undefined
                    }
                    loader={{
                        loadId: this.props.selectedAgency.id,
                        loadItems: this.loadItems.bind(this),
                    }}
                    headerDataItems={[
                        {
                            i18nKey: "groups.name",
                            headerKey: "name",
                            width: 16,
                        },
                    ]}
                    renderItem={(group: ClientGroup) => {
                        return (
                            <Table.Row
                                key={group.id}
                                style={{cursor: "pointer"}}
                                onClick={() =>
                                    this.setState({
                                        overlay: {
                                            key: "editGroup",
                                            group: group,
                                        },
                                    })
                                }
                            >
                                <Table.Cell
                                    style={{
                                        borderLeft: `10px solid ${group.color}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {group.name}
                                    <Icon name="chevron right" color="grey"/>
                                </Table.Cell>
                            </Table.Row>
                        );
                    }}
                    defaultSorting={{
                        column: "name",
                        direction: "asc",
                    }}
                    style={{flex: 1, display: "flex", flexDirection: "column"}}
                />
                <EditGroupOverlay
                    {...this.props}
                    viewStatus={
                        this.state.overlay.key == "editGroup"
                            ? {
                                key: "shown",
                                group: this.state.overlay.group,
                                client: user.toClient(),
                                agencyId: this.props.selectedAgency.id,
                            }
                            : {key: "hidden"}
                    }
                    onEditGroup={() =>
                        this.setState(
                            {overlay: {key: "none"}},
                            () => this.groupsTable && this.groupsTable.reload(),
                        )
                    }
                    onDismiss={this.closeOverlay.bind(this)}
                />
            </div>
        );
    }

    render() {
        let content;
        if (this.state.status.key == "loading") {
            content = <Loader active/>;
        } else if (this.state.status.key == "error") {
            content = (
                <div
                    className="flexCenteredContainer"
                    style={{width: "100%", height: "100%"}}
                >
                    <ZeroStateView
                        header={this.props.t("members.users.viewUserSheet.couldNotLoad")}
                        subheader={this.state.status.message}
                        icon="warning"
                    />
                </div>
            );
        } else {
            const user = this.state.status.user;

            content = (
                <div
                    style={{
                        padding: "30px 30px 15px 30px",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            marginBottom: "30px",
                        }}
                    >
                        <Header as="h2" style={{flex: 1, marginBottom: "0"}}>
                            {user.commonName}
                            <Header.Subheader>{user.email}</Header.Subheader>
                        </Header>
                        <Dropdown
                            pointing="right"
                            icon={{name: "ellipsis vertical", size: "large"}}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    icon="pencil"
                                    text={this.props.t(
                                        "members.users.viewUserSheet.dropdown.editInfo",
                                    )}
                                    onClick={() =>
                                        this.setState({overlay: {key: "editInfo"}})
                                    }
                                />
                                {this.props.selectedAgency.exposeExternalId ? (
                                    <Dropdown.Item
                                        icon="external"
                                        text={this.props.t(
                                            "members.users.viewUserSheet.dropdown.setExternalId",
                                        )}
                                        onClick={() =>
                                            this.setState({overlay: {key: "setExternalId"}})
                                        }
                                    />
                                ) : null}
                                <Dropdown.Item
                                    icon="envelope"
                                    text={this.props.t(
                                        "members.users.viewUserSheet.dropdown.editEmailAlerts",
                                    )}
                                    onClick={() =>
                                        this.setState({overlay: {key: "editEmailAlerts"}})
                                    }
                                />
                                <Dropdown.Item
                                    icon="options"
                                    text={this.props.t(
                                        `members.users.viewUserSheet.dropdown.${
                                            user.isManager ? "demoteAsManager" : "promoteToManager"
                                        }`,
                                    )}
                                    disabled={this.isSelf()}
                                    onClick={() =>
                                        this.setState({overlay: {key: "changeManagerStatus"}})
                                    }
                                />
                                <Dropdown.Item
                                    text={this.props.t(
                                        "members.users.viewUserSheet.dropdown.passwordResetEmail",
                                    )}
                                    onClick={() =>
                                        this.setState({overlay: {key: "passwordResetEmail"}})
                                    }
                                    icon={
                                        <Icon.Group style={{marginRight: ".78571429rem"}}>
                                            <Icon name="mail"/>
                                            <Icon corner name="lock"/>
                                        </Icon.Group>
                                    }
                                />
                                <Dropdown.Divider/>
                                <Dropdown.Item
                                    icon="x"
                                    text={this.props.t(
                                        "members.users.viewUserSheet.dropdown.removeUser",
                                    )}
                                    disabled={this.isSelf()}
                                    onClick={() =>
                                        this.setState({overlay: {key: "confirmRemoveUser"}})
                                    }
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {this.renderGroupsSection()}
                </div>
            );
        }
        const editInfoViewStatus: EditUserInfoModalViewStatus =
            this.state.status.key == "ready" && this.state.overlay.key == "editInfo"
                ? {
                    key: "shown",
                    agency: this.props.selectedAgency,
                    user: this.state.status.user,
                }
                : {key: "hidden"};
        const removeUserViewStatus: ConfirmRemoveClientModalViewStatus =
            this.state.status.key == "ready" &&
            this.state.overlay.key == "confirmRemoveUser"
                ? {
                    key: "shown",
                    agency: this.props.selectedAgency,
                    client: this.state.status.user.toClient(),
                }
                : {key: "hidden"};
        const setExternalIdViewStatus: SetClientExternalIdModalViewStatus =
            this.state.status.key == "ready" &&
            this.state.overlay.key == "setExternalId"
                ? {
                    key: "shown",
                    agencyId: this.props.selectedAgency.id,
                    clientId: this.state.status.user.id,
                }
                : {key: "hidden"};
        const editClientGroupsViewStatus: EditClientGroupsModalViewStatus =
            this.state.status.key == "ready" && this.state.overlay.key == "editGroups"
                ? {
                    key: "shown",
                    agencyId: this.props.selectedAgency.id,
                    client: this.state.status.user.toClient(),
                    groupType: this.state.groupsTabType,
                }
                : {key: "hidden"};
        const changeManagerViewStatus: ChangeManagerStatusModalViewStatus =
            this.state.status.key == "ready" &&
            this.state.overlay.key == "changeManagerStatus"
                ? {
                    key: "shown",
                    agency: this.props.selectedAgency,
                    user: this.state.status.user,
                }
                : {key: "hidden"};
        const editEmailAlertsViewStatus: EditEmailAlertsModalViewStatus =
            this.state.status.key == "ready" &&
            this.state.overlay.key == "editEmailAlerts"
                ? {key: "shown", clientId: this.state.status.user.id}
                : {key: "hidden"};
        const passwordResetEmailViewStatus: PasswordResetEmailModalViewStatus =
            this.state.status.key == "ready" &&
            this.state.overlay.key == "passwordResetEmail"
                ? {key: "shown", client: this.state.status.user}
                : {key: "hidden"};

        return (
            <Sheet
                open={this.props.viewStatus.key == "shown"}
                onDismiss={() => this.props.onDismiss(false)}
            >
                {content}
                <EditUserInfoModal
                    {...this.props}
                    viewStatus={editInfoViewStatus}
                    onSaveInfo={(info) => {
                        this.setState(
                            (prevState: ViewUserSheetState) => {
                                if (prevState.status.key == "ready") {
                                    prevState.status.user.updateWithBasicInfo(info);
                                    prevState.overlay = {key: "none"};
                                }
                                return prevState;
                            },
                            () => this.props.onUpdateUser(),
                        );
                    }}
                    onClose={this.closeOverlay.bind(this)}
                />
                <ConfirmRemoveClientModal
                    {...this.props}
                    viewStatus={removeUserViewStatus}
                    onConfirmRemove={() => {
                        this.closeOverlay();
                        this.props.onDismiss(true);
                    }}
                    onClose={this.closeOverlay.bind(this)}
                />
                <SetClientExternalIdModal
                    {...this.props}
                    viewStatus={setExternalIdViewStatus}
                    onClose={this.closeOverlay.bind(this)}
                />
                <EditClientGroupsModal
                    {...this.props}
                    viewStatus={editClientGroupsViewStatus}
                    onUpdateGroups={() => {
                        if (this.groupsTable != null) {
                            this.groupsTable.resetTable();
                        }
                    }}
                    onClose={this.closeOverlay.bind(this)}
                />
                <ChangeManagerStatusModal
                    {...this.props}
                    viewStatus={changeManagerViewStatus}
                    onConfirm={(newIsManager) => {
                        this.setState(
                            (prevState: ViewUserSheetState) => {
                                if (prevState.status.key == "ready") {
                                    prevState.status.user.isManager = newIsManager;
                                    prevState.overlay = {key: "none"};
                                }
                                return prevState;
                            },
                            () => this.props.onUpdateUser(),
                        );
                    }}
                    onClose={this.closeOverlay.bind(this)}
                />
                <EditEmailAlertsModal
                    {...this.props}
                    viewStatus={editEmailAlertsViewStatus}
                    onClose={this.closeOverlay.bind(this)}
                />
                <PasswordResetEmailModal
                    {...this.props}
                    viewStatus={passwordResetEmailViewStatus}
                    onClose={this.closeOverlay.bind(this)}
                />
            </Sheet>
        );
    }
}

export default withContext(ViewUserSheet, "api", "local", "i18n");
