import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { Loader, Menu, Message } from "semantic-ui-react";
import RouterMenuItem from "../../components/routerMenuItem";
import {
  ApiResult,
  AuthAgency,
  SCU,
  ScuTranslation,
  Translation,
} from "@bryxinc/lunch/models";

import { ControlUnitsTab } from "./controlUnitsTab";

import {StatusNotificationsTab} from "./statusNotificationsTab";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
  withContext,
  WithTranslation,
  WithLocal,
  WithApi,
} from "@bryxinc/lunch/context";
import { TranslationsTab } from "./translationsTab";
import {
  PollyResult,
  TranslateResult,
} from "@bryxinc/lunch/utils/ManagementApi/AgencyMixin";

export interface StationAlertingProps
  extends RouteComponentProps<object>,
    WithTranslation,
    WithLocal,
    WithApi<BryxApi> {
  selectedAgency: AuthAgency;
}

export interface StationAlertingState {
  scusLoadStatus:
    | { key: "loading" }
    | { key: "success"; scus: SCU[] }
    | { key: "error"; message: string };
  translationsLoadStatus:
    | { key: "loading" }
    | { key: "success"; translations: ScuTranslation[] }
    | { key: "error"; message: string };
  translateTestLoadStatus:
    | { key: undefined }
    | { key: "loading" }
    | { key: "success"; result: TranslateResult }
    | { key: "error"; message: string };
  overlay: "none";
}

export class StationAlertingPage extends React.Component<
  StationAlertingProps,
  StationAlertingState
> {
  constructor(props: StationAlertingProps) {
    super(props);
    this.state = StationAlertingPage.getInitialState();
  }

  private static getInitialState(): StationAlertingState {
    return {
      scusLoadStatus: { key: "loading" },
      translationsLoadStatus: { key: "loading" },
      translateTestLoadStatus: { key: undefined },
      overlay: "none",
    };
  }

  componentDidMount() {
    this.loadAlerts(this.props);
    this.loadTranslations(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: StationAlertingProps) {
    if (nextProps.selectedAgency.id != this.props.selectedAgency.id) {
      this.setState(StationAlertingPage.getInitialState(), () => {
        this.loadAlerts(nextProps);
        this.loadTranslations(nextProps);
      });
    }
  }

  private loadTranslations(props: StationAlertingProps) {
    this.setState({ translationsLoadStatus: { key: "loading" } });
    this.props.api.getScuTranslations(props.selectedAgency.id, (result) => {
      if (!result.success) {
        this.setState({
          translationsLoadStatus: {
            key: "error",
            message: `Failed to load scu translations: ${result.message}`,
          },
        });
      } else {
        this.setState({
          translationsLoadStatus: {
            key: "success",
            translations: result.value,
          },
        });
      }
    });
  }

  private loadTranslationResult(
    props: StationAlertingProps,
    raw: string,
    key: string
  ) {
    this.setState({ translateTestLoadStatus: { key: "loading" } });
    this.props.api.runNewTestTranslation(
      props.selectedAgency.id,
      raw,
      key,
      (result) => {
        if (result.success) {
          this.setState({
            translateTestLoadStatus: {
              key: "success",
              result: result.value,
            },
          });
        } else {
          this.setState({
            translateTestLoadStatus: { key: "error", message: result.message },
          });
        }
      }
    );
  }

  private loadAlerts(props: StationAlertingProps) {
    this.props.api.getAgencySCUs(props.selectedAgency.id, (result) => {
      if (result.success) {
        this.setState({
          scusLoadStatus: { key: "success", scus: result.value },
        });
      } else {
        this.props.local.logWarn(
          `Failed to load agency SCU alerts: ${result.message}`
        );
        this.setState({
          scusLoadStatus: { key: "error", message: result.message },
        });
      }
    });
  }

  render() {
    const { scusLoadStatus, translationsLoadStatus } = this.state;
    if (
      scusLoadStatus.key == "loading" ||
      translationsLoadStatus.key == "loading"
    ) {
      return (
        <div
          id="stationAlertingContent"
          className="pageContent horizontalNavAndContent"
        >
          <div className="underHorizNavContent tableUnderNavContent">
            <Loader active />
          </div>
        </div>
      );
    } else if (scusLoadStatus.key == "error") {
      return (
        <div
          id="stationAlertingContent"
          className="pageContent horizontalNavAndContent"
        >
          <Message
            negative
            content={scusLoadStatus.message}
            style={{ margin: "30px" }}
          />
        </div>
      );
    }
    return (
      <div
        id="stationAlertingContent"
        className="pageContent horizontalNavAndContent"
      >
        <div id="horizontalNav" className="Component_HorizontalNav">
          <Menu attached="top" tabular>
            <RouterMenuItem to="/station-alerting/control-units">
              {this.props.t("stationAlerting.tabs.controlUnits")}
            </RouterMenuItem>
            {scusLoadStatus.scus.length > 0 ? (
              <RouterMenuItem to="/station-alerting/status-notifications">
                {this.props.t("stationAlerting.tabs.statusNotifications")}
              </RouterMenuItem>
            ) : null}
            {translationsLoadStatus.key == "success" && translationsLoadStatus.translations.length >0 ? (
                <RouterMenuItem to="/station-alerting/translations">
                    Translations
                </RouterMenuItem>
            ) : null}
          </Menu>
        </div>

        <Switch>
          <Route
            path="/station-alerting/control-units"
            render={(props) => (
              <ControlUnitsTab
                {...this.props}
                selectedAgency={this.props.selectedAgency}
                scus={scusLoadStatus.scus}
                {...props}
              />
            )}
          />
          {scusLoadStatus.scus.length > 0 ? (
            <Route
              path="/station-alerting/status-notifications"
              render={(props) => (
                <StatusNotificationsTab
                  {...this.props}
                  selectedAgency={this.props.selectedAgency}
                  {...props}
                />
              )}
            />
          ) : null}
          {translationsLoadStatus.key == "success" &&
            translationsLoadStatus.translations.length > 0 && (
              <Route
                path="/station-alerting/translations"
                render={() => (
                  <TranslationsTab
                    translations={translationsLoadStatus.translations}
                    editTranslation={(
                      scuTranslationId: string,
                      stage: "constant" | "phoneme",
                      translation: Translation,
                      callback: (result: ApiResult<Translation>) => void
                    ) => {
                      this.props.api.editTranslationInStage(
                        this.props.selectedAgency.id,
                        scuTranslationId,
                        stage,
                        translation,
                        callback
                      );
                    }}
                    addTranslation={(
                      scuTranslationId: string,
                      stage: string,
                      translation: Translation,
                      callback: (result: ApiResult<Translation>) => void
                    ) => {
                      this.props.api.addTranslationToStage(
                        this.props.selectedAgency.id,
                        scuTranslationId,
                        stage,
                        translation,
                        callback
                      );
                    }}
                    deleteTranslation={(
                      scuTranslationId: string,
                      stage: string,
                      translationId: string,
                      callback: (result: ApiResult<null>) => void
                    ) => {
                      this.props.api.deleteTranslationFromStage(
                        this.props.selectedAgency.id,
                        scuTranslationId,
                        stage,
                        translationId,
                        callback
                      );
                    }}
                    getPollyTranslation={(
                      text: string,
                      callback: (result: ApiResult<PollyResult>) => void
                    ) =>
                      this.props.api.getPollyTranslation(
                        this.props.selectedAgency.id,
                        text,
                        callback
                      )
                    }
                    refetchTranslations={() =>
                      this.loadTranslations(this.props)
                    }
                    fetchTranslationResult={(raw: string, key: string) =>
                      this.loadTranslationResult(this.props, raw, key)
                    }
                    testTranslationResult={this.state.translateTestLoadStatus}
                  />
                )}
              />
            )}
          <Redirect to="/station-alerting/control-units" />
        </Switch>
      </div>
    );
  }
}

export default withContext(StationAlertingPage, "api", "local", "i18n");
